import axios from "axios";

// Google OAuth Configuration
const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
const clientSecret = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_SECRET;
const redirectUri = process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI;
const scope = process.env.REACT_APP_GOOGLE_OAUTH_SCOPE;
const tokenUrl = process.env.REACT_APP_GOOGLE_OAUTH_TOKEN_URL;
const userInfoUrl = process.env.REACT_APP_GOOGLE_OAUTH_USER_INFO_URL;

// Redirect the user to Google for authentication
export function redirectToGoogleAuth() {
  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}&scope=${encodeURIComponent(scope)}`;
  window.location.href = authUrl;
}

// Exchange authorization code for tokens
export async function handleGoogleCallback(authCode) {
  try {
    const response = await axios.post(tokenUrl, {
      code: authCode,
      client_id: clientId,
      client_secret: clientSecret,
      redirect_uri: redirectUri,
      grant_type: "authorization_code",
    });
    return response.data; // Contains access_token, id_token, etc.
  } catch (error) {
    console.error("Error exchanging authorization code for tokens:", error);
    throw error;
  }
}

// Fetch user profile information
export async function fetchGoogleUserInfo(accessToken) {
  try {
    const response = await axios.get(userInfoUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data; // User info like email, name, picture
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error;
  }
}
