import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/LessonsPage.css';

const Lesson2 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Lesson 2 Page',
      label: 'Lesson 2 Page',
    });
  }, []);

  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Lesson 2: System Design Framework</h1>
        <p>
          Designing complex systems requires a robust framework to ensure each
          component operates smoothly within the overall architecture. In this lesson, we’ll explore the essential components of a System Design Framework, from requirement analysis to documentation, which will help you build scalable, efficient, and maintainable systems.
        </p>

        {/* Requirement Analysis */}
        <section className="principle-section">
          <h2>1. Requirement Analysis</h2>
          <p>
            <strong>Purpose:</strong> Understanding functional and non-functional requirements is critical for guiding design decisions and meeting business goals.
          </p>
          <p><strong>Steps:</strong> Conduct stakeholder interviews, set scope boundaries, and identify system constraints.</p>
        </section>

        {/* System Decomposition */}
        <section className="principle-section">
          <h2>2. System Decomposition</h2>
          <p>
            <strong>Purpose:</strong> Breaking down complex systems into manageable, modular components simplifies development and maintenance.
          </p>
          <p><strong>Approach:</strong> Define major components like frontend, backend, and database layers, and identify dependencies.</p>
        </section>

        {/* Data Modeling */}
        <section className="principle-section">
          <h2>3. Data Modeling</h2>
          <p>
            <strong>Purpose:</strong> Constructing an effective data model helps define relationships and data flow across the system.
          </p>
          <p><strong>Key Focus:</strong> Choose between relational and NoSQL databases, optimize data structures, and map out entities and attributes.</p>
        </section>

        {/* Architecture Patterns */}
        <section className="principle-section">
          <h2>4. Architecture Patterns</h2>
          <p>
            <strong>Purpose:</strong> Patterns like microservices, monoliths, or serverless architectures impact system scalability, flexibility, and deployment.
          </p>
          <p><strong>Considerations:</strong> Assess trade-offs for each pattern based on project goals, scalability needs, and technical stack.</p>
        </section>

        {/* Communication Protocols */}
        <section className="principle-section">
          <h2>5. Communication Protocols</h2>
          <p>
            <strong>Purpose:</strong> Effective communication protocols (e.g., REST, gRPC) ensure seamless data exchange between components.
          </p>
          <p><strong>Choice Factors:</strong> Latency requirements, message size, and technology stack.</p>
        </section>

        {/* Scalability and Performance */}
        <section className="principle-section">
          <h2>6. Scalability and Performance</h2>
          <p>
            <strong>Purpose:</strong> Systems must handle increased load with minimal degradation.
          </p>
          <p><strong>Methods:</strong> Load balancing, database replication, and efficient caching strategies.</p>
        </section>

        {/* Security and Authentication */}
        <section className="principle-section">
          <h2>7. Security and Authentication</h2>
          <p>
            <strong>Purpose:</strong> Protecting user data and system integrity.
          </p>
          <p><strong>Approaches:</strong> Implement secure authentication, enforce data encryption, and follow best practices for API security.</p>
        </section>

        {/* Error Handling and Resilience */}
        <section className="principle-section">
          <h2>8. Error Handling and Resilience</h2>
          <p>
            <strong>Purpose:</strong> Design for fault tolerance and graceful degradation during failures.
          </p>
          <p><strong>Strategies:</strong> Circuit breakers, retries, and failover mechanisms.</p>
        </section>

        {/* Deployment and Infrastructure */}
        <section className="principle-section">
          <h2>9. Deployment and Infrastructure</h2>
          <p>
            <strong>Purpose:</strong> Streamline release cycles and manage resources effectively.
          </p>
          <p><strong>Focus Areas:</strong> Containerization (e.g., Docker), CI/CD pipelines, and cloud infrastructure.</p>
        </section>

        {/* Testing and Quality Assurance */}
        <section className="principle-section">
          <h2>10. Testing and Quality Assurance</h2>
          <p>
            <strong>Purpose:</strong> Ensure system stability and performance.
          </p>
          <p><strong>Best Practices:</strong> Employ unit, integration, and end-to-end testing across system components.</p>
        </section>

        {/* Monitoring and Logging */}
        <section className="principle-section">
          <h2>11. Monitoring and Logging</h2>
          <p>
            <strong>Purpose:</strong> Continuously track system health and identify issues early.
          </p>
          <p><strong>Tools:</strong> Utilize tools like Prometheus and Grafana for real-time monitoring and Elasticsearch for centralized logging.</p>
        </section>

        {/* Documentation */}
        <section className="principle-section">
          <h2>12. Documentation</h2>
          <p>
            <strong>Purpose:</strong> Clear, organized documentation helps streamline onboarding and system maintenance.
          </p>
          <p><strong>Include:</strong> API documentation, architecture diagrams, and troubleshooting guides.</p>
        </section>

        {/* Popular System Design Frameworks */}
        <section className="principle-section">
          <h2>Popular System Design Frameworks</h2>
          <p>
            Here are a few widely-adopted frameworks that follow structured approaches to system design:
          </p>
          <ul>
            <li><p><strong>4+1 Architectural View Model:</strong> Organizes system design into logical, process, development, and physical views, plus a use-case view.</p></li>
            <li><p><strong>C4 Model:</strong> Focuses on Context, Container, Component, and Code levels to document system architecture clearly.</p></li>
            <li><p><strong>TOGAF:</strong> An enterprise framework for defining and organizing IT architecture.</p></li>
            <li><p><strong>SABSA:</strong> A security-oriented framework tailored for complex systems.</p></li>
          </ul>
        </section>

        {/* Complete System Design Frameworks */}
        <section className="principle-section">
          <div className="lessons-image-container">
            <img src="/images/system-design-framework.avif" alt="System Design Framework" className="lessons-responsive-image" />
          </div>
        </section>

        {/* Redirections */}
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }} href="/lessons">Back To Lessons</a>
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)', marginLeft: '30px' }} href="/lessons/lesson3">Next Lesson</a>

      </div>
    </div>
  );
};

export default Lesson2;

