import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/LessonsPage.css';

const Lesson3 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Lesson 3 Page',
      label: 'Lesson 3 Page',
    });
  }, []);

  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Lesson 3: Domain-Driven Design - Microservices Style</h1>
        <p>
          Domain-Driven Design (DDD) is a software design approach that focuses on modeling software
          based on the complexities of the business domain. When combined with microservices,
          DDD provides a strong foundation for designing services that align with business
          capabilities, ensuring modularity and scalability.
        </p>

        {/* Understanding Domain-Driven Design */}
        <section className="principle-section">
          <h2>1. Understanding Domain-Driven Design (DDD)</h2>
          <p>
            <strong>Explanation:</strong> DDD revolves around the idea of creating a software model that
            reflects the business domain. Key concepts in DDD include the <strong>Ubiquitous Language</strong>,
            <strong>Bounded Context</strong>, and <strong>Domain Entities</strong>, which help bridge the gap
            between technical and business teams.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Ubiquitous Language:</strong> A common language shared by developers and domain
              experts. It ensures clarity and consistency in understanding requirements and functionality.
            </li>
            <li>
              <strong>Bounded Context:</strong> A clearly defined boundary within which a specific model is
              valid. This prevents overlapping definitions and ambiguity in the domain.
            </li>
            <li>
              <strong>Entities and Aggregates:</strong> Entities represent unique objects in the domain,
              while aggregates define a consistency boundary for operations.
            </li>
          </ul>

          <p>
            <strong>Example Use Case:</strong> In a healthcare application, the concept of a "Patient"
            may be modeled differently in the Billing context versus the Medical Records context. The
            Bounded Context ensures that the "Patient" model is tailored to the needs of each domain.
          </p>
        </section>

        {/* Applying DDD to Microservices */}
        <section className="principle-section">
          <h2>2. Applying DDD to Microservices</h2>
          <p>
            <strong>Explanation:</strong> Microservices architecture benefits significantly from DDD,
            as it encourages designing services around business domains. By aligning services with
            Bounded Contexts, we ensure that each service has a well-defined scope and responsibility.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Service Boundaries:</strong> Use Bounded Contexts to define service boundaries. This
              avoids overlapping responsibilities and enables independent evolution of services.
            </li>
            <li>
              <strong>Ubiquitous Language in APIs:</strong> Use the shared language from the domain model
              to design APIs, ensuring clarity and alignment with business concepts.
            </li>
            <li>
              <strong>Decentralized Data:</strong> Each service owns its data, ensuring consistency within
              its Bounded Context and avoiding shared databases.
            </li>
          </ul>

          <p>
            <strong>Example Use Case:</strong> In an e-commerce system, the "Inventory" service and
            "Order Management" service represent separate contexts. Each manages its data and
            operations, but they communicate through events such as "Order Placed" to update inventory
            levels.
          </p>
        </section>

        {/* Ubiquitous Language and API Design */}
        <section className="principle-section">
          <h2>3. Ubiquitous Language and API Design</h2>
          <p>
            <strong>Explanation:</strong> The Ubiquitous Language ensures that the terms used in API design
            match the business domain. This reduces misunderstandings and makes APIs more intuitive for
            developers and domain experts.
          </p>

          <p>
            <strong>Example Use Case:</strong> In a banking application, an API endpoint like
            <span className="highlighted-text">/accounts/{'{'}accountId{'}'}/transactions</span>
            clearly reflects the business concept of viewing transactions for a specific account.
          </p>
        </section>

        {/* Event-Driven Communication in DDD */}
        <section className="principle-section">
          <h2>4. Event-Driven Communication in DDD</h2>
          <p>
            <strong>Explanation:</strong> Events in DDD capture significant occurrences in the domain.
            In a microservices architecture, these events enable asynchronous communication between
            services, ensuring decoupling and scalability.
          </p>

          <p>
            <strong>Example Use Case:</strong> When a "Payment Completed" event is published in an
            online store, the "Order Service" updates the order status, and the "Shipping Service"
            prepares the package for delivery.
          </p>
        </section>

        {/* Challenges and Solutions in DDD with Microservices */}
        <section className="principle-section">
          <h2>5. Challenges and Solutions in DDD with Microservices</h2>
          <p>
            <strong>Explanation:</strong> While DDD provides a strong foundation for microservices,
            implementing it comes with challenges such as coordination between teams, managing data
            consistency, and designing effective APIs.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Challenge:</strong> Ensuring alignment across teams working on different services.
              <br />
              <strong>Solution:</strong> Regular domain workshops and shared documentation.
            </li>
            <li>
              <strong>Challenge:</strong> Handling data consistency in distributed systems.
              <br />
              <strong>Solution:</strong> Use eventual consistency with tools like Kafka for event
              streaming.
            </li>
            <li>
              <strong>Challenge:</strong> Designing APIs that reflect domain concepts.
              <br />
              <strong>Solution:</strong> Involve domain experts in API design and use
              behavior-driven development (BDD) techniques.
            </li>
          </ul>
        </section>

        {/* Conclusion */}
        <section className="principle-section">
          <h2>Conclusion</h2>
          <p>
            Domain-Driven Design is a powerful approach for building microservices that align closely
            with business needs. By leveraging concepts like Ubiquitous Language, Bounded Contexts, and
            Event-Driven Communication, you can create a scalable and maintainable architecture that
            supports evolving business requirements.
          </p>
        </section>

        {/* Redirections */}
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }}
          href="/lessons"
        >
          Back To Lessons
        </a>
        <a
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '1rem',
            color: 'rgb(0, 84, 147)',
            marginLeft: '30px',
          }}
          href="/lessons/lesson4"
        >
          Next Lesson
        </a>
      </div>
    </div>
  );
};

export default Lesson3;
