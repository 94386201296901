// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutMePage from './pages/AboutMePage';
import ContactMePage from './pages/ContactMePage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import MyAccount from './profile/MyAccount';
import LessonsPage from './pages/LessonsPage';

import AdminRoute from './authentication/AdminRoute';
import ResourcesPage from './pages/ResourcesPage';
import ManageUsers from './profile/ManageUsers';
import CodeInsightsPage from './pages/CodeInsightsPage';
import ResumeInsightsPage from './pages/ResumeInsightsPage';
import EmailSubscriptionPage from './pages/EmailSubscriptionPage';
import SendLessonEmail from './profile/SendLessonEmail';

import Lesson1 from './lessons/Lesson1';
import Lesson2 from './lessons/Lesson2';
import Lesson3 from './lessons/Lesson3';
import Lesson4 from './lessons/Lesson4';
import Lesson5 from './lessons/Lesson5';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/about-me" element={<AboutMePage />} />
        <Route path="/contact" element={<ContactMePage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/auth/google/callback" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/subscribe" element={<EmailSubscriptionPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/code-insights" element={<CodeInsightsPage />} />
        <Route path="/resume-insights" element={<ResumeInsightsPage />} />
        
        <Route path="/resources" element={<ResourcesPage />} />  
        <Route path="/manage-users" element={<AdminRoute><ManageUsers /></AdminRoute>} />
        <Route path="/send-lesson-email" element={<AdminRoute><SendLessonEmail /></AdminRoute>} />

        <Route path="/lessons" element={<LessonsPage />} />
        <Route path="/lessons/lesson1" element={<Lesson1 />} />
        <Route path="/lessons/lesson2" element={<Lesson2 />} />
        <Route path="/lessons/lesson3" element={<Lesson3 />} />
        <Route path="/lessons/lesson4" element={<Lesson4 />} />
        <Route path="/lessons/lesson5" element={<Lesson5 />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
