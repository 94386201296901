import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/LessonsPage.css';

const Lesson4 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Lesson 4 Button',
      label: 'Lesson 4 Page',
    });
  }, []);

  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Lesson 4: Event-Driven Architecture Essentials</h1>
        <p>
          Event-Driven Architecture (EDA) is a powerful design pattern that enables asynchronous communication
          and decoupling between services. It’s a key enabler for building reactive, scalable, and resilient
          systems.
        </p>

        {/* What Are Events? */}
        <section className="principle-section">
          <h2>1. What Are Events?</h2>
          <p>
            <strong>Explanation:</strong> An event is a significant occurrence or change in state within a system.
            Events are immutable and provide a way to communicate changes between different components or services.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Event Producer:</strong> The source of the event, such as a service or system that detects and
              records a change.
            </li>
            <li>
              <strong>Event Channel:</strong> The medium through which events are transmitted, such as a message broker
              like Kafka or RabbitMQ.
            </li>
            <li>
              <strong>Event Consumer:</strong> The entity that listens to and processes the event, such as another
              service or component.
            </li>
          </ul>

          <p>
            <strong>Example:</strong> In an e-commerce application, when a user places an order, an "Order Placed"
            event is generated, which is consumed by other services like Inventory and Shipping.
          </p>
        </section>

        {/* Core Components of EDA */}
        <section className="principle-section">
          <h2>2. Core Components of Event-Driven Architecture</h2>
          <p>
            <strong>Explanation:</strong> EDA comprises key components that enable seamless communication and processing:
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Event Processor:</strong> Processes incoming events and triggers actions based on business logic.
            </li>
            <li>
              <strong>Event Store:</strong> A storage mechanism to persist events for future reference, debugging,
              or replay.
            </li>
            <li>
              <strong>Event Bus:</strong> Facilitates the routing of events between producers and consumers.
            </li>
          </ul>

          <p>
            <strong>Example:</strong> In a banking system, a "Transaction Completed" event may trigger notifications
            and update account balances through dedicated event processors.
          </p>
        </section>

        {/* Benefits of EDA */}
        <section className="principle-section">
          <h2>3. Benefits of Event-Driven Architecture</h2>
          <p>
            <strong>Explanation:</strong> EDA offers multiple advantages, making it a preferred choice for modern
            distributed systems.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Decoupling:</strong> Services can operate independently, reducing dependencies and enhancing scalability.
            </li>
            <li>
              <strong>Asynchronous Communication:</strong> Improves system performance by enabling non-blocking interactions.
            </li>
            <li>
              <strong>Real-Time Processing:</strong> Supports reactive systems that respond to events in near real-time.
            </li>
          </ul>

          <p>
            <strong>Example:</strong> Social media platforms use EDA to update feeds in real time when a user posts new content.
          </p>
        </section>

        {/* Challenges and Solutions */}
        <section className="principle-section">
          <h2>4. Challenges and Solutions in EDA</h2>
          <p>
            <strong>Explanation:</strong> While EDA is powerful, it comes with its own set of challenges, which can be mitigated with best practices.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Challenge:</strong> Event Ordering and Delivery Guarantees.
              <br />
              <strong>Solution:</strong> Use message brokers with support for ordering and retry mechanisms.
            </li>
            <li>
              <strong>Challenge:</strong> Debugging and Monitoring.
              <br />
              <strong>Solution:</strong> Implement centralized logging and monitoring tools to trace event flows.
            </li>
            <li>
              <strong>Challenge:</strong> Data Consistency in Distributed Systems.
              <br />
              <strong>Solution:</strong> Use eventual consistency patterns and event sourcing.
            </li>
          </ul>
        </section>

        {/* Conclusion */}
        <section className="principle-section">
          <h2>Conclusion</h2>
          <p>
            Event-Driven Architecture is a game-changer for modern systems, enabling loose coupling, scalability,
            and real-time responsiveness. By understanding and leveraging events, event processors, and event
            channels, you can design systems that meet the demands of today’s dynamic environments.
          </p>
        </section>

        {/* Redirections */}
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }}
          href="/lessons"
        >
          Back To Lessons
        </a>
        <a
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '1rem',
            color: 'rgb(0, 84, 147)',
            marginLeft: '30px',
          }}
          href="/lessons/lesson5"
        >
          Next Lesson
        </a>
      </div>
    </div>
  );
};

export default Lesson4;
