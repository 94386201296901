import React, { useEffect } from 'react';
import '../styles/AboutMe.css'; // Make sure to include your CSS file
import { trackOutboundLinkClick } from '../components/analytics';
import ReactGA from 'react-ga4';

const AboutMe = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit AboutMe Page',
      label: 'AboutMe Page',
    });
  }, []);
  return (
    <div className="about-me-section">
      <div className="about-me-content">
      <div className="about-me-image">
        <img src="/images/bhuvnesh.avif" alt="About Me" />
      </div>
        <h1>Bhuvnesh Arya</h1>
        <p1><b>Architect, Technologist, and Mentor</b></p1>
        <p>
        I am a passionate software architect and engineering leader with over a decade of experience in the technology industry. My journey began in 2010, and over the years, I’ve cultivated expertise in system design, scalable architectures, and cutting-edge engineering practices.
        </p>
        <p>
        As a <b>Software Architect at Wesco</b> and former <b>Lead Developer at Siemens</b>, I specialize in <b>Software Architecture, IoT, and Cloud Computing</b>. These roles, combined with my commitment to mentoring and educating through InsightVeda.com, enable me to provide real-world insights and guidance to freshers and developers looking to grow their skills.
        </p>
        <p>
        I founded InsightVeda.com to empower developers with actionable insights, resources, and tools to excel in their craft. The platform delivers lessons on software architecture, system design, and modern engineering practices, fostering continuous learning and growth.
        </p>
        <p>
        Beyond my professional endeavors, I have actively contributed as a volunteer in various non-profit organizations, <b>mentoring hundreds of professionals and college students</b>. My focus has been on helping them build essential skills, gain confidence, and thrive in the competitive tech industry. I’m deeply passionate about giving back to society and supporting the next generation of engineers.
        </p>
        <p>Follow me on</p>
        
        <div class="social-links-aboutme">
          <a href="https://www.linkedin.com/in/bhuvnesh-arya/" target="_blank" class="social-link linkedin" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.linkedin.com/in/bhuvnesh-arya/')}>
            <img src="/images/linkedin.png" alt="LinkedIn" />
          </a>
          <a href="https://x.com/Bhuvneshdot/" target="_blank" class="social-link twitter" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://x.com/Bhuvneshdot/')}>
            <img src="/images/twitter-x.png" alt="LinkedIn" />
          </a>
        </div>

      </div>
      
    </div>
  );
};

export default AboutMe;
