import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  LinearProgress
} from '@mui/material';
import '../styles/ResumeInsightsPage.css'; // Add specific styles for Resume Insights
import { UserContext } from '../contexts/UserContext';
import { Bar } from 'react-chartjs-2';
import '../styles/CodeInsightsPage.css';
import GaugeChart from 'react-gauge-chart';
import ReactGA from 'react-ga4';

const ResumeInsightsPage = () => {
  const [file, setFile] = useState(null);
  const [textDescription, setTextDescription] = useState('');
  const [apiError, setApiError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [data, setData] = useState(null);
  const [taskId, setTaskId] = useState(null);

  const { isLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit ResumeInsights Page',
      label: 'ResumeInsights Page',
    });
  }, []);

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setApiError('');
    setSuccessMessage('');
    setData(null);
    setTaskId(null);
    setProgress(0);
  };

  const handleTextDescriptionChange = (event) => {
    setTextDescription(event.target.value);
  };

  const fetchProgress = async (taskId, intervalId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/progress/${taskId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch progress.');
      }
      const progressData = await response.json();
      setProgress(progressData.progress);
  
      if (progressData.status === 'COMPLETED') {
        fetchResult(taskId);
        setIsAnalyzing(false);
        setSuccessMessage('');
        clearInterval(intervalId); // Stop polling
      } else if (progressData.status === 'FAILED') {
        setApiError('Analysis failed. Please try again.');
        setIsAnalyzing(false);
        setSuccessMessage('');
        clearInterval(intervalId); // Stop polling
      }
    } catch (error) {
      setApiError(error.message);
      setIsAnalyzing(false);
      setSuccessMessage('');
      clearInterval(intervalId); // Stop polling on error
    }
  };
  
  const fetchResult = async (taskId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/resume-review/result/${taskId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch analysis result.');
      }
      const result = await response.json();
      setSuccessMessage('');
      setData(result);
    } catch (error) {
      setApiError(error.message);
    }
  };
  
  const handleSubmit = async () => {
    console.log(taskId);
    if (!isLoggedIn) {
      navigate('/sign-in');
      return;
    }
  
    if (!file || !textDescription.trim()) {
      setApiError('Please provide both a resume file and a text description or job description.');
      return;
    }
  
    setApiError('');
    setSuccessMessage('');
    setIsUploading(true);
    setData(null);
    setTaskId(null);
    setProgress(0);
  
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('textDescription', textDescription.trim());
  
      const response = await fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/resume-review/analyze`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        if (response.status === 429) {
          const errorMessage = (await response.text()) || 'You have reached your daily scan limit.';
          throw new Error(errorMessage);
        }
        throw new Error('Failed to start analysis.');
      }
  
      const result = await response.json();
      setTaskId(result.taskId);
      setSuccessMessage('Analysis started successfully. Tracking progress...');
      setIsAnalyzing(true);
  
      // Polling for progress with interval ID tracking
      const intervalId = setInterval(() => {
        fetchProgress(result.taskId, intervalId);
      }, 10000);
    } catch (error) {
      setApiError(error.message);
    } finally {
      setIsUploading(false);
    }
  };
  

  const colors = ['#FF0000', '#FFA500', '#FFFF00', '#00FF00'];

  return (
    <div className="resume-insights-page-container">
      <div className="resume-insights-content">
      <h1>Resume Insights</h1>
      <p>
        <b style={{ fontStyle: 'italic' }}>Elevate your career prospects with AI-powered resume analysis.</b>
      </p>
      <p>
        Discover how Resume Insights revolutionizes the way you refine your resume. With cutting-edge AI, we’ve made resume evaluation faster, smarter, and more effective.
      </p>
      <p>
        Upload your resume file and provide a text or job description. Let AI deliver comprehensive insights into your strengths, opportunities for improvement, and how to align your profile with your dream job. Take the guesswork out of resume reviews and focus on landing your next opportunity.
      </p>
      <p><strong>File Upload Instructions:</strong></p>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        <li style={{ marginBottom: '8px' }}>
          <span style={{ color: 'red' }}>✔</span> Supported formats: 
          <strong> .pdf, .docx, .txt</strong>
        </li>
        <li style={{ marginBottom: '8px' }}>
          <span style={{ color: 'red' }}>✔</span> Limits: Max file size <strong>5MB</strong> | <strong>3</strong> scans
        </li>
      </ul>
      {/* Display API Error or Success Message */}
      {apiError && <div style={{ color: 'red' }}>{apiError}</div>}
      {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 2 }}>
      
      {/* File Upload Input */}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="fileUpload">Upload Resume File:</label>
          <TextField
            fullWidth
            value={file ? file.name : ''}
            placeholder="Select a resume file..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" component="label">
                    Select File
                    <input type="file" accept=".pdf,.docx,.txt" hidden onChange={handleFileUpload} />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {/* Add line space */}
          <Box sx={{ marginTop: 2 }} />
          {/* Job Description Input */}
          <TextField
            fullWidth
            multiline
            minRows={3}
            placeholder="Enter a job description or your desired role..."
            value={textDescription}
            onChange={handleTextDescriptionChange}
            sx={{
              height: 100,
              overflowY: 'auto',
              resize: 'none',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
            }}
          />
        </div>
      </form>

      {/* Conditional Rendering Based on Login Status */}
      {!isLoggedIn ? (
        <Button variant="contained" onClick={() => navigate('/sign-in')}>
          Sign in to Continue
        </Button>
      ) : (
        <>
          {!isAnalyzing ? (
            <Button
              variant="contained"
              disabled={isUploading}
              onClick={handleSubmit}
              startIcon={isUploading && <CircularProgress size={20} color="inherit" />}
            >
              {isUploading ? 'Extracting Data...' : 'Show Analysis'}
            </Button>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ width: '100%', marginBottom: 2 }}
              />
              <p style={{ marginTop: 5, fontSize: '0.875rem', color: 'gray' }}>
                {progress}% Complete
              </p>
            </div>
          )}
        </>
      )}
    </Box>

        {/* Display Insights */}
    {data && (
      <Box sx={{ padding: '16px' }}>
        <Grid container spacing={3}>
            {/* Overall Score */}
                          <Grid item xs={12}>
                            <Card>
                              <CardContent style={{ height: '100%', position: 'relative' }}>
                                <Typography
                                  variant="h2"
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    left: '20px',
                                    margin: 0,
                                  }}
                                >
                                  <strong>Overall Score</strong>
                                </Typography>
            
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                  }}
                                >
                                  <GaugeChart
                                    id="gauge-chart"
                                    nrOfLevels={30}
                                    colors={colors}
                                    percent={(data.compositeScore / 10) * 100 / 100}
                                    arcWidth={0.3}
                                    textColor="#333"
                                    needleColor="#333"
                                    style={{ width: '50%', height: '50%' }}
                                    formatTextValue={(value) => `${data.compositeScore}/10`}
                                  />
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>

          {/* Core Competency Metrics */}
                        <Grid item xs={12} md={6}>
                          <Card style={{ height: '100%' }}>
                            <CardContent>
                              <Typography variant="h2">
                                <strong>Core Competency Metrics</strong>
                              </Typography>
                              <Box sx={{ height: '100%', width: '100%' }}>
                                <Bar
                                  data={{
                                    labels: ['Skills', 'Experience', 'Education'],
                                    datasets: [
                                      {
                                        label: 'Ratings',
                                        data: [
                                          data.skillsRating,
                                          data.experienceRating,
                                          data.educationRating,
                                        ],
                                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                                      },
                                    ],
                                  }}
                                  options={{
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    plugins: {
                                      legend: {
                                        position: 'bottom',
                                      },
                                    },
                                  }}
                                />
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>     

          {/* Supporting Proficiency */}
                        <Grid item xs={12} md={6}>
                          <Card style={{ height: '100%' }}>
                            <CardContent>
                              <Typography variant="h2">
                                <strong>Supporting Proficiency</strong>
                              </Typography>
                              <Box sx={{ height: '100%', width: '100%' }}>
                                <Bar
                                  data={{
                                    labels: ['Language Skills', 'Certifications', 'Presentation'],
                                    datasets: [
                                      {
                                        label: 'Ratings',
                                        data: [
                                          data.languageSkillsRating,
                                          data.certificationsRating,
                                          data.presentationRating
                                        ],
                                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                                      },
                                    ],
                                  }}
                                  options={{
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    plugins: {
                                      legend: {
                                        position: 'bottom',
                                      },
                                    },
                                  }}
                                />
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>

          {/* Context-Specific Feedback */}
          <Grid item xs={12} md={6}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h2">
                  <strong>Context-Specific Feedback</strong>
                </Typography>
                <Typography variant="h3">
                  {data.contextSpecificFeedback.description}
                </Typography>
                <Typography variant="h3">
                  <b>Profile Matching Score:</b> {data.contextSpecificFeedback.profileMatchingScore}/100
                </Typography>
                <Typography variant="h3" sx={{ marginTop: 1 }}>
                  <b>Skills Matching Percentage:</b> {data.contextSpecificFeedback.skillsMatchingPercentage}%
                </Typography>
                <Typography variant="h3" sx={{ marginTop: 1 }}>
                  <b>Relevant Skills:</b>
                </Typography>
                <ul>
                  {data.contextSpecificFeedback.skills.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>
                <Typography variant="h3" sx={{ marginTop: 1 }}>
                  <b>Relevant Experience Matching Percentage:</b> {data.contextSpecificFeedback.relevantExperienceMatchingPercentage}%
                </Typography>
                <Typography variant="h3" sx={{ marginTop: 1 }}>
                  <b>Relevant Experiences:</b>
                </Typography>
                <ul>
                  {data.contextSpecificFeedback.relevantExperience.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>
                <Typography variant="h3" sx={{ marginTop: 1 }}>
                  <b>Specific Feedbacks:</b>
                </Typography>
                <ul>
                  {data.contextSpecificFeedback.feedbacks.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>

          {/* Keyword Optimization */}
          <Grid item xs={12} md={6}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h2">
                  <strong>Keyword Optimization</strong>
                </Typography>
                {data.keywordOptimization.missingKeywords.length > 0 ? (
                  <ul>
                    {data.keywordOptimization.missingKeywords.map((keyword, index) => (
                      <li key={index}>{keyword}</li>
                    ))}
                  </ul>
                ) : (
                  <Typography>No missing keywords identified.</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* ATS Compatibility */}
          <Grid item xs={12} md={12}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h2">
                  <strong>ATS Compatibility</strong>
                </Typography>
                <Typography variant="h3">
                  {data.atsCompatibilityCheck.isCompatible
                    ? 'The resume is ATS compatible.'
                    : 'The resume is not ATS compatible.'}
                </Typography>
                {data.atsCompatibilityCheck.formattingIssues.length > 0 && (
                  <>
                    <Typography variant="h3" sx={{ marginTop: 1 }}>
                      <b>Formatting Issues:</b>
                    </Typography>
                    <ul>
                      {data.atsCompatibilityCheck.formattingIssues.map((issue, index) => (
                        <li key={index}>{issue}</li>
                      ))}
                    </ul>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Deep Insights */}
          <Grid item xs={12}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h2">
                  <strong>Deep Insights</strong>
                </Typography>
                <Typography variant="h3"><b>Strengths:</b></Typography>
                <ul>
                  {data.deepInsights.strengths.map((strength, index) => (
                    <li key={index}>{strength}</li>
                  ))}
                </ul>
                <Typography variant="h3"><b>Areas of Improvement:</b></Typography>
                <ul>
                  {data.deepInsights.areasOfImprovement.map((area, index) => (
                    <li key={index}>{area}</li>
                  ))}
                </ul>
                <Typography variant="h3"><b>Additional Recommendations:</b></Typography>
                <ul>
                  {data.deepInsights.additionalRecommendations.map((recommendation, index) => (
                    <li key={index}>{recommendation}</li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Box>
    )}
      </div>
    </div>
  );
};

export default ResumeInsightsPage;
