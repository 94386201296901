import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  LinearProgress
} from '@mui/material';
import { UserContext } from '../contexts/UserContext';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import '../styles/CodeInsightsPage.css';
import GaugeChart from 'react-gauge-chart';
import ReactGA from 'react-ga4';

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CodeInsightsPage = () => {
  const [file, setFile] = useState(null);
  const [apiError, setApiError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [data, setData] = useState(null);
  const [taskId, setTaskId] = useState(null);

  const { isLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit CodeInsights Page',
      label: 'CodeInsights Page',
    });
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      e.target.value = null;
    }
    setApiError('');
    setSuccessMessage('');
    setData(null);
    setTaskId(null);
    setProgress(0);
  };

  const fetchProgress = async (taskId, intervalId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/progress/${taskId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch progress.');
      }
      const progressData = await response.json();
      setProgress(progressData.progress);
  
      if (progressData.status === 'COMPLETED') {
        fetchResult(taskId);
        setIsAnalyzing(false);
        setSuccessMessage('');
        clearInterval(intervalId); // Stop polling
      } else if (progressData.status === 'FAILED') {
        setApiError('Analysis failed. Please try again.');
        setIsAnalyzing(false);
        setSuccessMessage('');
        clearInterval(intervalId); // Stop polling
      }
    } catch (error) {
      setApiError(error.message);
      setIsAnalyzing(false);
      setSuccessMessage('');
      clearInterval(intervalId); // Stop polling on error
    }
  };
  
  const fetchResult = async (taskId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/code-review/result/${taskId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch analysis result.');
      }
      const result = await response.json();
      setSuccessMessage('');
      setData(result);
    } catch (error) {
      setApiError(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(taskId);
    if (!isLoggedIn) {
      navigate('/sign-in');
      return;
    }

    if (!file) {
      setApiError('Please select a source file to analyze.');
      return;
    }

    try {
      let zipFile = file;

      await startAnalysis(zipFile);
    } catch (error) {
      setApiError(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const startAnalysis = async (zipFile) => {
    setApiError('');
    setSuccessMessage('');
    setIsUploading(true);
    setData(null);
    setTaskId(null);
    setProgress(0);

    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('file', zipFile);

      const response = await fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/code-review/analyze`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 429) {
          const errorMessage = (await response.text()) || 'You have reached your daily scan limit.';
          throw new Error(errorMessage);
        }
        throw new Error('Failed to start analysis.');
      }

      const result = await response.json();
      setTaskId(result.taskId);
      setSuccessMessage('Analysis started successfully. Tracking progress...');
      setIsAnalyzing(true);

      // Polling for progress with interval ID tracking
      const intervalId = setInterval(() => {
        fetchProgress(result.taskId, intervalId);
      }, 10000);
    } catch (error) {
      setApiError(error.message);
    }
  };

  const colors = ['#FF0000', '#FFA500', '#FFFF00', '#00FF00'];

  return (
    <div className="code-insights-page-container">
      <div className="code-insights-content">
      <h1>Code Insights</h1>
      <p>
        <b style={{ fontStyle: 'italic' }}>Supercharge your code reviews with AI-powered analysis.</b>
      </p>
      <p>
        Discover how Code Insights transforms the way you analyze your codebase. With cutting-edge AI, we’ve streamlined code analysis to make it faster, smarter, and effortless.
      </p>
      <p>
        Upload your source code as a ZIP file and let AI handle the heavy lifting. Gain detailed insights into your code quality, performance, security, maintainability, and more in seconds. Take the guesswork out of code reviews and focus on building better software.
      </p>
      <p><strong>File Upload Instructions:</strong></p>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        <li style={{ marginBottom: '8px' }}>
          <span style={{ color: 'red' }}>✔</span> Supported languages: 
          <strong> Java, Python, JavaScript, TypeScript, Go, Rust, Swift, Kotlin, Ruby, PHP, Scala</strong>
        </li>
        <li style={{ marginBottom: '8px' }}>
          <span style={{ color: 'red' }}>✔</span> Limits: Max file size <strong>5MB</strong> | <strong>3</strong> scans
        </li>
      </ul>

      {apiError && <div style={{ color: 'red' }}>{apiError}</div>}
      {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 2 }}>
      {/* File Input Embedded in Text Field */}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="fileUpload">Upload Source Code Zip:</label>
          <TextField
            variant="outlined"
            fullWidth
            value={file ? file.name : ''}
            placeholder="Select a zip file..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" component="label">
                    Select File
                    <input type="file" accept=".zip" hidden onChange={handleFileChange} />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </form>

      {/* Conditional Rendering Based on Login Status */}
      {!isLoggedIn ? (
        <Button variant="contained" onClick={() => navigate('/sign-in')}>
          Sign in to Continue
        </Button>
      ) : (
        <>
          {/* File Analysis Status and Actions */}
          {!isAnalyzing ? (
            <Button
              variant="contained"
              disabled={isUploading}
              onClick={handleSubmit}
              startIcon={isUploading && <CircularProgress size={20} color="inherit" />}
            >
              {isUploading ? 'Extracting Data...' : 'Show Analysis'}
            </Button>
          ) : (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              <p style={{ marginTop: '5px', fontSize: '0.875rem', color: 'gray' }}>
                {progress}% Complete
              </p>
            </div>
          )}
        </>
      )}
    </Box>

        

        {/* Analysis Dashboard */}
        {data && (
          <Box sx={{ padding: '16px' }}>
            <Grid container spacing={3} alignItems="stretch">
              {/* Overall Score */}
              <Grid item xs={12}>
                <Card>
                  <CardContent style={{ height: '100%', position: 'relative' }}>
                    <Typography
                      variant="h2"
                      style={{
                        position: 'absolute',
                        top: '10px',
                        left: '20px',
                        margin: 0,
                      }}
                    >
                      <strong>Overall Score</strong>
                    </Typography>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <GaugeChart
                        id="gauge-chart"
                        nrOfLevels={30}
                        colors={colors}
                        percent={(data.compositeScore / 10) * 100 / 100}
                        arcWidth={0.3}
                        textColor="#333"
                        needleColor="#333"
                        style={{ width: '50%', height: '50%' }}
                        formatTextValue={(value) => `${data.compositeScore}/10`}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              {/* Code Quality and Design Metrics */}
              <Grid item xs={12} md={6}>
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h2">
                      <strong>Code Quality and Design Metrics</strong>
                    </Typography>
                    <Box sx={{ height: '100%', width: '100%' }}>
                      <Bar
                        data={{
                          labels: ['Design', 'Code Quality', 'Documentation', 'Performance', 'Test Coverage'],
                          datasets: [
                            {
                              label: 'Ratings',
                              data: [
                                data.designRating,
                                data.codeQualityRating,
                                data.documentationRating,
                                data.performanceRating,
                                data.testCoverageRating,
                              ],
                              backgroundColor: 'rgba(75, 192, 192, 0.6)',
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            legend: {
                              position: 'bottom',
                            },
                          },
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Architectural Quality and Best Practices */}
              <Grid item xs={12} md={6}>
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h2">
                      <strong>Architectural Quality and Best Practices</strong>
                    </Typography>
                    <Box sx={{ height: '100%', width: '100%' }}>
                      <Bar
                        data={{
                          labels: ['Security', 'Scalability', 'Reliability', 'Maintainability', 'Best Practices'],
                          datasets: [
                            {
                              label: 'Ratings',
                              data: [
                                data.securityRating,
                                data.scalabilityRating,
                                data.reliabilityRating,
                                data.maintainabilityRating,
                                data.bestPracticesRating,
                              ],
                              backgroundColor: 'rgba(75, 192, 192, 0.6)',
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            legend: {
                              position: 'bottom',
                            },
                          },
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Code Improvement Recommendations */}
              <Grid item xs={12} md={6}>
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h2">
                      <strong>Code Improvement Recommendations</strong>
                    </Typography>
                    <Typography variant="h3">
                      <strong>Areas of Improvement:</strong>
                    </Typography>
                    <ul>
                      {data.deepInsights.areasOfImprovement.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>

                    <Typography variant="h3">
                      <strong>Design Considerations:</strong>
                    </Typography>
                    <ul>
                      {data.deepInsights.designConsiderations.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>

                    <Typography variant="h3">
                      <strong>Testing Improvements:</strong>
                    </Typography>
                    <ul>
                      {data.deepInsights.testingImprovements.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>

              {/* Impact Analysis and Scalability */}
              <Grid item xs={12} md={6}>
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h2">
                      <strong>Impact Analysis and Scalability</strong>
                    </Typography>
                    <Typography variant="h3">
                      <strong>Potential Impacts:</strong>
                    </Typography>
                    <ul>
                      {Object.entries(data.deepInsights.potentialImpacts).map(([key, value], index) => (
                        <li key={index}>
                          <strong>{key}:</strong> {value}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>

              {/* Validation Feedback */}
              {data.validationFeedback?.validationFeedback &&
                Object.keys(data.validationFeedback.validationFeedback).length > 0 && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Card style={{ height: '100%' }}>
                        <CardContent>
                          <Typography variant="h2">
                            <strong>Rating Changes Since Last Scan</strong>
                          </Typography>
                          <ul>
                            {data.validationFeedback.validationFeedback.differencesInRatings.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card style={{ height: '100%' }}>
                        <CardContent>
                          <Typography variant="h2">
                            <strong>Suggestions Based on Last Scan</strong>
                          </Typography>
                          <ul>
                            {data.validationFeedback.validationFeedback.suggestions.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                )}
            </Grid>
          </Box>
        )}



      </div>
    </div>
  );
};

export default CodeInsightsPage;
