import React, { useState, useEffect } from 'react';
//import { supabase } from '../authentication/supabaseClient'; // Import your supabase client
import '../styles/ResetPasswordPage.css';
import ReactGA from 'react-ga4';

const ResetPasswordPage = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Reset Password Button',
      label: 'Reset Password Page',
    });
  }, []);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  /*const handlePasswordReset = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) {
      setMessage("Error: " + error.message);
    } else {
      setMessage("Password reset link has been sent to your email.");
    }
  };*/

  console.log(setMessage);

  return (
    <div className="reset-password-container">
        <h2>Reset Password</h2>
        <p>Please enter your email address, and we'll send you a link to reset your password.</p>
        <form>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="reset-password-button">Send Reset Link</button>
        </form>
        {message && <p>{message}</p>}
    </div>
  );
};

export default ResetPasswordPage;
