import React, { useEffect } from 'react';
import '../styles/Introduction.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const ResumeInsights = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Resume Insights Button',
      label: 'Resume Insights Page',
    });
  }, []);

  return (
    <div className="intro-container">
      <div className="intro-content">
        <div className="intro-image">
          <a href="/resume-insights"><img src="/images/resume-insights.avif" alt="Resume Insights" /></a>
        </div>
        <div className="home-container">
          <p>
            <Link style={{color: '#0069d2'}} to="/resume-insights"><b>Resume Insights</b></Link> is an AI-powered tool that offers comprehensive feedback on your resume. It analyzes key aspects such as skills alignment, experience relevance, education, ATS compatibility, and formatting. Get detailed insights into your resume’s strengths, areas of improvement, and how well it matches your target job description.
          </p> 
        </div>
      </div>
    </div>
  );
}

export default ResumeInsights;
